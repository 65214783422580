import React from "react";
import { useSelector } from "react-redux";
import OfferItem from "./Item";
import "./offers.scss";
import { useParams } from "react-router-dom";

const Offers = (props) => {
  const params = useParams();
  let isLoading = !params.id ? false : true,
    queryId = params ? params.id : false,
    deepLinkOffer = params.id ? findOffer(params.id) : "";
  let available = useSelector((state) => state.offer.collections.available);
  let featured = useSelector((state) => state.offer.collections.featured);
  // const {
  //    REACT_APP_COLOR_LINKS: links,
  // } = process.env;


  function findOffer (id) {
    let deepLinkOffer = available.find(
      (cpn) => cpn.mdid === parseInt(id)
    );
    isLoading = false;
    return deepLinkOffer;
  }

  function RenderDeepLink (props) {
    const { cpn } = props;
    return (
      <div>
        <div className="SingleOffer">
          <OfferItem coupon={cpn} key={cpn.mdid} />
        </div>
      </div>
    );
  }

  function RenderOfferList (props) {
    return (
      <div className="offerContent">
        <h2 className="sectionTitle">In Store Digital Coupons</h2>
        <div className="OfferList">
          {props.available.map((cpn) => {
            return <OfferItem coupon={cpn} key={cpn.mdid} />;
          })}
        </div>
      </div>
    );
  }
  function RenderFeaturedList(props) {
    return (
      <div className="FeaturedOfferList">
        {props.featured.map((cpn) => {
          return <OfferItem coupon={cpn} key={cpn.mdid} isFeatured={true} />;
        })}
      </div>
    );
  }
  function trackViewMoreEvent (){
    window.gtag('event', 'View More Coupons', { 
      retailer_id: "Publix"
    });
  }



  if (isLoading) {
    return "";
  }
  
  if (queryId && deepLinkOffer) {
    return <RenderDeepLink cpn={deepLinkOffer} />;
  } else if (!queryId && (available.length > 0 || featured.length > 0)) {
    return (
      <div>
        {featured.length > 0 && <RenderFeaturedList featured={featured} />}
        <RenderOfferList available={available} />
        <br></br>
        <a 
          className="viewMore" 
          href="https://www.publix.com/savings/digital-coupons" 
          style={{ color: '#64ac44' }} 
          onClick={trackViewMoreEvent}
        >View more Publix digital coupons...</a>
      </div>
    );
  } else {
    return "There are no available offers";
  }
};

export default Offers;
