import React from 'react';
import RecipeItem from './item';


function RecipeContentItem(props) {
  const { sectionTitle, sectionImage } = props.section;
  const sectionTitleImage = `/images/${process.env.REACT_APP_BRAND_NAME}/recipes/${sectionImage}`;

  // function trackAddToCartEvent (){
  //   window.gtag('event', 'addtocartclick', { 
  //     product: title
  //    });
  // }

  return (
    <div className="recipe-section" id={sectionTitle}>
      {/* <h3 className='sectionTitle'>{sectionTitle}</h3> */}
      <img src={sectionTitleImage} alt={sectionTitle}/>
      <div className='recipeBlock'>
        {props.section.sectionItems.map((recipe, i) => {
          return <RecipeItem recipe={recipe} key={i}></RecipeItem>;
        })}
      </div>
      <div className='clearer'></div>
    </div>
  );
}
export default RecipeContentItem;
