import React, {useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './hero.scss';

function Hero(props) {
  const brand = process.env.REACT_APP_BRAND_NAME;
  const imgPath = '/images/' + brand + '/'; 
  const [width, setWidth] = useState(window.innerWidth);

  const gardenBG = props.hero.garden[0].background.hasBG === 'true' ? imgPath + props.hero.garden[0].background.image : '';
  const gardenStyle = {
    backgroundImage: "url(" + gardenBG + ")"
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;


  function StaticHero(){
    const hero = props.hero.staticHero;
    return(
      <img
        className={`heroImg`}
        src={imgPath + (!isMobile ? hero.image : hero.mobile)}
        alt={hero.alt}
      />
    )
  }
  function HeroCarousel() {
    const heroCarousel = props.hero.carouselHero;
    return (
      <Carousel
        autoPlay={true}
        interval={5000}
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        swipeable={true}
        onClickItem={anchorItem}
      >
        {heroCarousel.map((item, i) => {
          return(
            <a href={item.anchor} key={i}>
              <img
                className={`heroImg`}
                src={imgPath + (!isMobile ? item.image : item.mobile)}
                alt={item.alt}
              />
            </a>
          )
        })}
      </Carousel>
    );
  }
  function anchorItem(i,child){
    if(child.props.href){
      window.location.href = child.props.href;
    }
  }
  function GardenImages(){
    const garden = props.hero.garden[0].icons;
    return (
      <div>
        {garden.map((item, i) => {
          return(
            <a href={item.link} target="_Blank" rel="noopener noreferrer" key={i}>
              <img
                className={`gardenImg`}
                src={imgPath + item.image}
                alt={item.alt}
              />
            </a>
          )
        })}
      </div>
    )
  }
  return (
    
    <div className='header'>
      <div className="hero">
        { props.hero.carouselHero && <HeroCarousel/> }
        { props.hero.staticHero && <StaticHero/>}
      </div>
      <div className='garden' style={gardenStyle}>
        <GardenImages/>
      </div>
    </div>
  );
}
export default Hero;
